const DonePage = (props) => {
  return (
    <div className='flex flex-col h-full m-auto p-0 overscroll-none'>
      <div id='header' className='bg-metamor-purple w-full p-4 flex-none'>
        <img
          src='/images/metamor_logo.svg'
          alt='metamor'
          className='w-3/5 md:w-1/2 lg:w-1/4'
        />
      </div>
      <div className='text-center flex-1 p-4 md:max-w-md mx-auto'>
        <img src='/images/metamor-done.jpg' alt='Thank you!' />
        <div className='p-2 bg-white rounded text-left w-4/5 md:w-2/3'>
          <h1 className='text-3xl'>Your images have been received!</h1>
          <img
            src='/images/metamor-divider.svg'
            alt='divider'
            className='w-3/4 my-8'
          />
          <p>
            We're verifying your benefits now and will do our best to let you
            know if a bariatric procedure is available to you within…
          </p>
          <p className='font-bold'>7 business days</p>
        </div>
      </div>
      <div className='text-gray-500 bg-metamor-grey text-xs text-center flex-none p-4'>
        Powered by
        <img
          alt='Powered by Care Sherpa'
          src='/images/cs-logo-horiz.svg'
          className='m-auto w-3/5 md:w-40'
        />
      </div>
    </div>
  )
}

export default DonePage
