import React, { Fragment } from "react"
import ReactDOM from "react-dom"
import AppRouter from "./routes/AppRouter"
import "./index.css"
import reportWebVitals from "./reportWebVitals"

ReactDOM.render(
  <React.StrictMode>
    <Fragment>
      <AppRouter />
    </Fragment>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
