import React, { Suspense, lazy } from "react"
import { Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import DefaultPage from "../components/DefaultPage"
import DonePageMetamor from "../components/DonePageMetamor"
import DonePageLourdesBar from "../components/DonePageLourdesBar"

export const history = createBrowserHistory()

const App = lazy(() => import("../App"))

const AppRouter = () => (
  <Router history={history}>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path='/001/:uid' component={() => <App clientID={`001`} />} />
        <Route path='/002/:uid' component={() => <App clientID={`002`} />} />
        <Route path='/003/:uid' component={() => <App clientID={`003`} />} />
        <Route path='/metamor' component={() => <DonePageMetamor />} />
        <Route path='/lourdesbar' component={() => <DonePageLourdesBar />} />
        <Route path='*' component={() => <DefaultPage />} />
      </Switch>
    </Suspense>
  </Router>
)

export default AppRouter
